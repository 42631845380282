import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import SecondaryLanding from "../../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo";
import Icon from "../../../../components/custom-widgets/icon";
import HeroChevron from "../../../../components/hero/hero-chevron";
import NotificationAlert from "../../../../components/notifications/notification-alert";

import getHeroImgVariables from "../../../../helpers/getHeroImgVariables";

const HomeownerAssistance = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/homeowner-assistance/hero-homeowners-assistance-05242023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "homeowner-assistance-hero",
    ...getHeroImgVariables(imgData),
    altText: "Father and his son laughing and talking on their porch at home.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "WaFd Bank Homeowner Assistance Programs"
          }
        },
        {
          id: 2,
          button: {
            id: "homeowner-assistance-hero-cta",
            text: "Learn how to apply",
            url: "#how-to-apply-section",
            class: "btn-white",
            type: "anchor-link"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Homeowner Assistance"
    }
  ];

  const SEOData = {
    title: "Home Owner Assistance",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Home Owner Assistance"
      },
      {
        name: "description",
        content:
          "WaFd Bank's Homeowners Assistance Program allows monthly mortgage payments to be deferred for up to three months."
      },
      {
        property: "og:title",
        content: "Home Owner Assistance"
      },
      {
        property: "og:description",
        content:
          "WaFd Bank's Homeowners Assistance Program allows monthly mortgage payments to be deferred for up to three months."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/homeowner-assistance"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-homeowners-assistance-05242023.jpg"
      }
    ]
  };

  const TitleSection = () => {
    return (
      <section className="container">
        <h1>WaFd Bank Homeowner Assistance</h1>
        <span className="h3">
          WaFd Bank's Homeowner Assistance Department is here to assist our mortgage customers experiencing financial
          difficulty. A variety of options that may be available are:
        </span>
      </section>
    );
  };

  const OptionsColumn = ({ options }) => {
    return options.map(({ title, text }) => {
      return (
        <div className="options-column">
          <h5 className="m-0">{title}</h5>
          {text && <p>{text}</p>}
        </div>
      );
    });
  };

  const OptionSection = ({ customClass, title, optionsLeft, optionsRight }) => {
    return (
      <section className={"container " + customClass}>
        <h3 className="text-success font-weight-bold">{title}</h3>
        <div className="row">
          <div className="col-12 col-md-6">
            <OptionsColumn options={optionsLeft} />
          </div>
          <div className="col-12 col-md-6">
            <OptionsColumn options={optionsRight} />
          </div>
        </div>
      </section>
    );
  };

  const HowToApplySection = () => {
    return (
      <section id="how-to-apply-section" className="container">
        <h3 className="text-success font-weight-bold">How to Apply</h3>
        <div className="row">
          <div className="col-12 col-md-6">
            <p>
              Below you will find our application and a list of documents required. There may be additional documents or
              information requested upon review of your application. If you are unable to access the form linked below,
              contact us and we will send it to you.
            </p>
            <p>
              <strong>Please note:</strong> Loans secured by second homes or rental properties, home equity lines of
              credit (HELOCs), lot loans, and other personal loans are not eligible for Homeowner Assistance.
            </p>
            <ul>
              <li>
                <a
                  id="ha-application-doc-link"
                  className="underline"
                  href="/documents/homeowner-assistance-application-010523.pdf"
                  target="_blank"
                  title="Homeowner Assistance Application"
                >
                  Homeowner Assistance Application
                </a>
                , fully completed and signed
              </li>
              <li>Pay stubs, if applicable, for the prior two months</li>
              <li>All W-2's received for the prior year</li>
              <li>
                All bank and investment account statements, excluding retirement accounts, for the prior two months
              </li>
              <li>
                <div className="mb-1">If self-employed and/or receiving rental income:</div>
                <ul className="mt-2">
                  <li>Prior two (2) years signed tax returns with all schedules</li>
                  <li>
                    Prior two fiscal year-end and year to date financial statements (profit and loss and balance sheet)
                    for any entities owned by you
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <NotificationAlert
              type="warning"
              id="email-error-notification-alert"
              bodyText="Because we value your privacy, please do not email the application documents. When you are ready to submit your application, you may email us for a secure link to submit it electronically."
            />
            <p>
              Alternatively, you may drop off the package at your local WaFd Bank branch or mail it to the address
              below. If you have any questions or need assistance, please email us at{" "}
              <a id="ha-email-link" href="mailto:homeownerassistance@wafd.com">
                homeownerassistance@wafd.com
              </a>{" "}
              or call us at{" "}
              <a id="ha-tel-link" href="tel:866-453-9710">
                866-453-9710
              </a>
              .
            </p>
            <p className="font-weight-bold">
              WaFd Bank <br />
              Attn: Homeowner Assistance Department <br />
              425 Pike Street <br />
              Seattle, WA 98101
            </p>
          </div>
        </div>
      </section>
    );
  };

  const AdditionalResource = ({ title, tel, externalLink, id }) => {
    return (
      <div id={`${id}-resource`} className="col-12 col-md-6 mb-2 mb-md-0">
        <h5>{title}</h5>
        <div>
          <Icon lib="fal" name="phone-alt" fixedWidth="1.75rem" containerClass="mr-1 float-left text-primary" />{" "}
          <a id={`${id}-phone`} href={`tel:${tel}`} className="d-block overflow-hidden text-decoration-none">
            {tel}
          </a>
        </div>
        <div>
          <Icon lib="fal" name="external-link" fixedWidth="1.75rem" containerClass="mr-1 float-left text-primary" />{" "}
          <a id={`${id}-external-link`} href={externalLink} className="d-block overflow-hidden text-decoration-none">
            {externalLink}
          </a>
        </div>
      </div>
    );
  };

  const AdditionalResourcesSection = () => {
    return (
      <section className="bg-light">
        <div className="container">
          <h2>Additional Resources</h2>
          <div className="row">
            <div className="col-12 col-md-6">
              <p>
                For a list of HUD-approved housing counseling agencies that can provide foreclosure prevention
                information, contact one of the following federal government agencies:
              </p>
            </div>
          </div>
          <div className="row">
            {additionalResourcesData.map((resource) => {
              return <AdditionalResource {...resource} />;
            })}
          </div>
        </div>
      </section>
    );
  };

  const optionsKeeping = {
    customClass: "pt-0",
    title: "Options for Keeping Your Home",
    optionsLeft: [
      {
        title: "Repayment Plan",
        text: "Repayment plan allows you to pay past due mortgage payments, along with your regular monthly payment, in installments over time.  During the repayment period you will have a higher monthly mortgage payment."
      },
      {
        title: "Hardship Modification",
        text: "A mortgage loan modification provides a temporary change to the existing terms of the loan. The monthly mortgage payment is modified, either by adjusting the amortization, interest rate, or both. "
      },
      {
        title: "Refinance",
        text: "Mortgage loan can be refinanced with either WaFd Bank or another lender."
      }
    ],
    optionsRight: [
      {
        title: "Forbearance Plan",
        text: "Forbearance plan suspends the regular monthly mortgage payments for a designated period of time allowing you the time to improve or stabilize the financial hardship. Missed payments are not forgiven and considered past due until the loan is brought current.  All missed payments will be due at the end of the forbearance period."
      },
      {
        title: "Deferral",
        text: "Short-term mortgage loan deferral due to a hardship being experienced from declared emergency. Program originated in response to the COVID-19 pandemic but may also be used for situations where a state of emergency has been declared, such as widespread fire, flood or inclement weather. Deferred loan payments are added to the back end of the loan to be paid as a balloon payment at loan payoff or maturity (not inclusive of monthly escrowed insurance and/or taxes)."
      }
    ]
  };

  const optionsSelling = {
    customClass: "py-0",
    title: "Options for Selling or Transferring Your Home",
    optionsLeft: [
      {
        title: "Short Sale",
        text: "The home securing the mortgage may be sold for less than you owe. We will review your application to determine the amount and terms of repayment for the remaining balance."
      },
      {
        title: "Selling Your Home for More than the Mortgage Balance",
        text: "A forbearance plan that suspends the regular monthly mortgage payments for a designated period of time allowing you time to sell your home. Missed payments are not forgiven and considered past due until the loan is paid off."
      }
    ],
    optionsRight: [
      {
        title: "Deed-in-Lieu of Foreclosure",
        text: "The ownership of the home securing the mortgage may be transferred to WaFd Bank at an agreed to value. We will review your application to determine the amount and terms of repayment for the remaining balance."
      }
    ]
  };

  const additionalResourcesData = [
    {
      id: "hud-counseling",
      title: "The U.S. Department of Housing and Urban Development (HUD)",
      tel: "800-569-4287",
      externalLink: "https://www.hud.gov/counseling"
    },
    {
      id: "consumerfinance-mortgagehelp",
      title: "The Consumer Financial Protection Bureau (CFPB)",
      tel: "855-411-2372",
      externalLink: "https://www.consumerfinance.gov/mortgagehelp"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      
      <TitleSection />

      <OptionSection {...optionsKeeping} />
      <OptionSection {...optionsSelling} />

      <HowToApplySection />
      <AdditionalResourcesSection />
    </SecondaryLanding>
  );
};

export default HomeownerAssistance;
